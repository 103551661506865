import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Category" } }, [_c("text-input", { key: "categoryName", attrs: { "form-item": "", "rules": "required|max:256", "label": "Category", "max-length": 256, "span": 24 } }), _c("select-input", { key: "portfolioID", attrs: { "form-item": "", "rules": "required", "label": "Portfolio", "data-source": _vm.listPortfolio, "source": "id", "source-label": "portfolioName", "placeholder": "Select Portfolio", "span": 24 } })], 1);
};
var staticRenderFns$1 = [];
render$1._withStripped = true;
const __vue2_script$1 = {
  name: "CreateCategoryData",
  inject: ["apiUrl"],
  data() {
    return {
      listPortfolio: []
    };
  },
  async created() {
    const { data } = await this.axios.get(`${this.apiUrl}/shipment-closure/portfolio-data?fe=((IsInactive Eq 'false'))`);
    this.listPortfolio = data.results;
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
__component__$1.options.__file = "src/views/CategoryData/CreateCategoryData/CreateCategoryData.vue";
var CreateCategoryData = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-category-data" }, [_c("resource", { attrs: { "name": "shipment-closure.category-data", "api-url": _vm.apiUrl, "redirect-route": "/shipment-closure/category-data" } }, [_c("create-category-data")], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateCategoryData
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
__component__.options.__file = "src/views/CategoryData/CreateCategoryData/index.vue";
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
